:root {
  --color-primary: #324688;
  --color-primary-2: #364f6b;
  --color-dark-grey: #8b8f95;
  --color-black: #000;
  --color-dark-grey: #8b8f95;
  --color-dark-grey-2: #757575;
  --color-light-grey: #757575;
  --color-light-grey-2: #d9d9d9;
  --color-light-grey-3: #dfdfdf;
  --color-light-grey-4: #f5f5f5;
  --color-white: #ffffff;
  --color-blue: #527bbe;
  --color-orange: #f0573a;
  --color-sky-blue: #007bff;
  --color-green: #038b57;
  --color-red: #ff471a;
  --color-pink: #fc5185;
  --color-cyan: #3fc1cb;
  --button-primary: #324688;
  --button-text-primary: #ffffff;
  --bs-body-bg: #f8f9fa;
  --color-light-cyan: #61d0d8;
  --bs-breadcrumb-divider-rtl: url("../../studio-store-ecommerce-components/src/BreadCrumbs/images/arrow-left.svg");
  --bs-breadcrumb-divider: url("../../studio-store-ecommerce-components/src/BreadCrumbs/images/arrow-right.svg");
  --bs-breadcrumb-item-padding-x: 0.25rem;
  --bs-breadcrumb-item-padding-x-modified: 0.25rem;
}

body {
  padding-right: 0 !important
}

* {
  font-family: Rubik, sans-serif;
}

#root ::-webkit-scrollbar {
  width: 5px;
}

#root ::-webkit-scrollbar-track {
  background: #f1f1f1;
  width: 5px;
}

#root ::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 5px;
}

#root ::-webkit-scrollbar-thumb:hover {
  background: #555;
}

#root br {
  display: none;
}





.bg-light-grey-4 {
  background-color: var(--color-light-grey-4);
}



.f-xs {
  font-size: 12px !important;
}
.f-sm {
  font-size: 14px !important;
}

.f-md {
  font-size: 18px !important;
}

@media only screen and (min-width: 0px) and (max-width: 544px) {
  .f-s-xs {
    font-size: 12px !important;
  }
  .f-s-sm {
    font-size: 14px !important;
  }
  .f-s-md {
    font-size: 18px !important;
  }
}
@media only screen and (min-width: 545px) and (max-width: 767px) {
  .f-md-xs {
    font-size: 12px !important;
  }
  .f-md-sm {
    font-size: 14px !important;
  }
  .f-md-md {
    font-size: 18px !important;
  }
  .mb-md-40 {
    margin-bottom: 2.5rem !important;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .f-lg-xs {
    font-size: 12px !important;
  }
  .f-lg-sm {
    font-size: 14px !important;
  }
  .f-lg-md {
    font-size: 18px !important;
  }
  .mb-lg-40 {
    margin-bottom: 2.5rem !important;
  }
}

@media only screen and (min-width: 992px) {
  .f-xl-xs {
    font-size: 12px !important;
  }
  .f-xl-sm {
    font-size: 14px !important;
  }
  .f-xl-md {
    font-size: 18px !important;
  }
  .mb-xl-40 {
    margin-bottom: 2.5rem !important;
  }
}



.mb-40 {
  margin-bottom: 2.5rem !important;
}

@media only screen and (min-width: 0px) {
  .mb-xs-40 {
    margin-bottom: 2.5rem !important;
  }
}

@media only screen and (min-width: 768px) {
  .mb-md-40 {
    margin-bottom: 2.5rem !important;
  }
}

@media only screen and (min-width: 992px) {
  .mb-lg-40 {
    margin-bottom: 2.5rem !important;
  }
}



.text-dark-grey-2 {
  color: var(--color-dark-grey-2) !important;
}



.btn-md {
  padding: 11px 55px !important;
}

.btn {
  line-height: 24px !important;
  border-radius: 5px !important;
  padding: 11px 18px;
  box-shadow: none !important;
  outline: none !important;
}

.btn-primary-1 {
  background-color: var(--color-cyan) !important;
  border-color: transparent !important;
  color: var(--color-ButtonTextRegular) !important;
}

.btn-primary-1:hover {
  background-color: var(--color-primary-hover-1) !important;
}

.btn-outline-primary-1 {
  background-color: var(--color-ButtonTextRegular) !important;
  border-color: var(--color-ButtonBackRegular) !important;
  color: var(--color-ButtonBackRegular) !important;
}

.btn-outline-primary-1:hover {
  background-color: var(--color-primary-hover-4);
}

.btn-secondary {
  background-color: var(--color-ButtonBackRegular) !important;
  border-color: var(--color-ButtonBackRegular) !important;
  color: var(--color-ButtonTextRegular);
}

.btn-secondary:hover {
  background-color: var(--color-cyan);
}

.btn-outline-secondary {
  border-color: var(--color-cyan) !important;
  color: var(--color-cyan) !important;
}

.btn-outline-secondary:hover {
  background-color: var(--color-secondary-hover-4) !important;
  color: var(--color-ButtonTextHover) !important;
}

.btn-link {
  color: var(--color-cyan) !important;
}

.btn-link:hover {
  font-weight: 500;
  color: var(--color-cyan) !important;
}

.btn-block {
  width: 100% !important;
}

.p-6 {
  padding: 4rem;
}

.px-6 {
  padding-left: 4rem;
  padding-right: 4rem;
}

.py-6 {
  padding-top: 4rem;
  padding-bottom: 4rem;
}



.gx-64 {
  --bs-gutter-x: 4rem !important;
}
.gx-109 {
  --bs-gutter-x: 6.8125rem !important;
}

[dir="rtl"] .form-control.is-invalid, .was-validated .form-control:invalid {
  background-position: left calc(0.375em + 0.1875rem) center !important;
}

[dir="rtl"] .text-start {
  text-align: right !important;
}
.text-end {
  text-align: right !important;
}
[dir="rtl"] .text-end {
  text-align: left !important;
}

@media (min-width: 0px) {
  .container {
    max-width: 100% !important;
    padding-left: 16px !important;
    padding-right: 16px !important;
  }
  .carousel-responsive-container {
    min-height: auto;
    padding-bottom: 0.5em !important;
  }
}

@media (min-width: 768px) {
  .gx-md-64 {
    --bs-gutter-x: 4rem !important;
  }
  .gx-md-109 {
    --bs-gutter-x: 6.8125rem !important;
  }
  .gx-md-40 {
    --bs-gutter-x: 2.5rem !important;
  }
  .carousel-responsive-container {
    min-height: auto;
    padding-bottom: 2em !important;
  }
}

@media (min-width: 992px) {
  .gx-lg-64 {
    --bs-gutter-x: 4rem !important;
  }
  .gx-lg-109 {
    --bs-gutter-x: 6.8125rem !important;
  }
  .gx-lg-40 {
    --bs-gutter-x: 2.5rem !important;
  }
  .category-minimal--size-md .category-minimal__img {
    height: 260px !important;
    object-fit: cover !important;
    object-position: center !important;
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1600px !important;
  }
}

@media (min-width: 1300px) {
  .container {
    max-width: 1312px !important;
  }
  .yt-head-hide-srch-col {
    position: relative !important;
    left: 22.5% !important;
  }
}

@media (min-width: 992px) {
  .carousel-responsive-container .rec-carousel-wrapper {
    margin-left: -12px !important;
    margin-right: -12px !important;
    width: calc(100% + 24px) !important;
  }
}

@media (min-width: 0px) and (max-width: 991px) {
  .carousel-responsive-container {
    max-width: 100% !important;
    padding: 0px !important;
  }
}

iframe {
  display: none;
}

.MuiCircularProgress-colorPrimary {
  color: var(--color-ButtonBackRegular) !important;
}
.breadcrumb {
  padding: 0px 0.25rem !important;
}
.breadcrumb-item+.breadcrumb-item {
  padding-left: 0.25rem !important;
  padding-right: var(--bs-breadcrumb-item-padding-x-modified) !important;
}
.breadcrumb-item+.breadcrumb-item::before {
  float: left !important;
  content: var(--bs-breadcrumb-divider, "/");
  padding-right: var(--bs-breadcrumb-item-padding-x-modified) !important;
  padding-left: 0px !important;

}
[dir="rtl"] .breadcrumb-item+.breadcrumb-item::before {
  content: var(--bs-breadcrumb-divider-rtl, "/");
  float: right !important;
  padding-left: var(--bs-breadcrumb-item-padding-x-modified) !important;
  padding-right: 0px !important;
}
[dir="rtl"] .breadcrumb-item {
  padding-left: var(--bs-breadcrumb-item-padding-x-modified) !important;
}
.cursor-pointer {
  cursor: pointer !important;
}